import React from 'react';
import { Link } from 'react-scroll';

import OutstripImg from '../../images/logo/outstrip-192.png';
import OutstripLightImg from '../../images/logo/outstrip-light-logo.png';

const OutstripLogo = ({ textColor, imgTheme }) => {
  const color = textColor || 'text-black';
  const imgSrc = imgTheme === 'light' ? OutstripLightImg : OutstripImg;
  return (
    <Link
      className='cursor-pointer flex justify-start items-center'
      to="hero"
      spy={true}
      smooth={true}
      offset={-50}
      duration={500}
    >
      <img src={imgSrc} alt="Outstrip's logo" className='w-10 md:w-12' />
      <span className={`hidden md:inline-block ml-2 text-3xl ${color}`}>Outstrip</span>
    </Link>
  );
}

export default OutstripLogo;
