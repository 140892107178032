import React from 'react';

import Header from '../Layouts/Header';
import Footer from '../Layouts/Footer';

import WhatsappIcon from '../../svg/whatsapp.svg';

const OpenRoutes = ({ element }) => {

  return (
    <div>
      <Header />
      <div className='pt-24'>{element}</div>
      <Footer />
      {/* <a href="https://api.whatsapp.com/send?phone=8610643474" className="whatsapp-btn">
        <img style={{ width: '3rem' }} src={WhatsappIcon} alt="Contact us through Whatsapp" />
      </a> */}
    </div>
  );
}

export default OpenRoutes;
