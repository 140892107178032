import React from 'react';

const renderContent = (content) => content || 'Try it for FREE';

const getButtonStyle = (type) => {
  if (type === 'big') {
    return 'font-bold text-2xl md:text-4xl py-4 px-5 md:px-9';
  }
  if (type === 'base') {
    return 'font-bold text-sm md:text-base py-2 px-3 md:px-6';
  }
  return 'px-8 py-2 text-xl md:text-2xl';
}

const TryforFreeButton = ({ content, type }) => {
  const buttonStyle = getButtonStyle(type);
  return (
    <a className={`${buttonStyle} bg-gradient border border-none rounded-full text-white`} href='https://app.outstrip.biz/sign-up'>
      {renderContent(content)}
    </a>
  )
};

export default TryforFreeButton;
