import React from 'react';

const SubTerms = ({ data }) => {
  return <ol>{data.map(renderData)}</ol>;
}

const renderData = (jData) => {
  return (
      <li key={jData.id} className="">
        <div className="bold">{jData.title}</div>
        <ol>{jData.contents.map(renderContent)}</ol>
      </li>
  );
}

const renderContent = (contentData, index) => {
  return (
    <li key={index}>
      <div className="pt-1">{contentData.name}</div>
      {renderContentPoints(contentData)}
    </li>
  );
}

const renderContentPoints = (contentData) => {
  if (contentData.points && contentData.points.length > 0) {
    return <ol>{contentData.points.map(renderList)}</ol>;
  }
  return null;
}

const renderSubpoint = (subpoint, index) => {
  return <li className="subpoint-list" key={index}>{subpoint}</li>
}

const renderSubpoints = (list) => {
  if (list.subpoints && list.subpoints.length > 0) {
    return <ul>{list.subpoints.map(renderSubpoint)}</ul>
  }
  return null;
}

const renderList = (list, index) => {
  return (
    <li className="pt-1" key={index}>
      <div>{list.data}</div>
      {renderSubpoints(list)}
    </li>
  );
}

export default SubTerms;
