import React from 'react';

import VideoJS from '../../../component/VideoJs';
import TryforFreeButton from '../../../component/TryforFreeButton';

const videoJsOptions = {
  controls: true,
  responsive: true,
  fluid: true,
  fill: true,
  sources: [{
    src: 'https://outstrip-media-assets.s3.ap-south-1.amazonaws.com/outstrip-demo.mp4',
    type: 'video/mp4'
  }]
};

const Hero = () => (
  <div id='hero' className='block md:grid grid-cols-2 items-center justify-center px-4 mx-4 md:mx-12 md:min-h-[500px]'>
    <div className='p-2 md:p-5'>
      <div className='text-4xl md:text-6xl text-gradient'>
        <p>Get a 360<sup className='text-gradient'>o</sup> view</p>
        <p>of your social media</p>
        <p>in seconds</p>
      </div>
      <div className='pt-10 text-base md:text-xl'>
        <p>Spend your time and energy on building your brand…</p>
        <p>leave analytics & reports to us!</p>
      </div>
      <div className='pt-10'>
        <TryforFreeButton />
      </div>
    </div>
    <div className='pt-5'>
      <VideoJS options={videoJsOptions} />
    </div>
  </div>
);

export default Hero;
