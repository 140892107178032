import React from 'react';

import Card from './Card';
import TryforFreeButton from '../../../component/TryforFreeButton';

const cardDetails = [
  {
    id: 1,
    title: 'Ease of use',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={29}
        fill="none"
      >
        <path
          fill="#FA5C18"
          d="M13.829 27.59c-.096-1.977-.478-3.877-1.57-5.57-1.105-1.715-2.712-2.624-4.722-2.846-.369-.04-.777.028-1.1-.11-.255-.109-.568-.463-.562-.698.008-.233.347-.592.603-.659.568-.147 1.186-.092 1.763-.214 2.779-.588 4.337-2.462 5.048-5.073.321-1.179.403-2.428.525-3.653.052-.522.111-1.003.717-.997.6.006.723.491.705 1.015-.054 1.555.235 3.051.802 4.495 1.079 2.743 3.087 4.213 6.037 4.378.173.01.396-.05.509.036.247.187.601.443.605.678.005.235-.32.617-.572.689-.515.146-1.085.08-1.617.18-2.753.515-4.412 2.275-5.155 4.862-.377 1.31-.438 2.716-.607 4.084-.061.486-.225.836-.752.812-.48-.021-.672-.351-.657-.815.006-.198 0-.396 0-.596v.002zm5.158-9.205c-2.282-1.051-3.593-2.835-4.466-5.141-.857 2.33-2.183 4.085-4.45 5.138 2.267 1.048 3.594 2.824 4.46 5.145.863-2.325 2.18-4.09 4.456-5.142zM11.54 7.204c-.215.128-.496.418-.816.467-2.839.434-4.228 2.023-4.297 4.98-.004.173.064.386-.012.513-.17.28-.372.678-.627.736-.453.102-.664-.25-.722-.722-.11-.901-.177-1.83-.45-2.688-.545-1.718-1.738-2.714-3.603-2.79-.488-.019-1-.075-1.013-.718-.013-.693.5-.729 1.033-.754C3.04 6.135 4.475 4.773 4.89 2.553c.105-.556.148-1.126.173-1.693.022-.472.116-.855.68-.86.558-.004.644.366.7.842.102.878.194 1.775.457 2.611.561 1.78 1.827 2.687 3.703 2.776.512.024.87.167.935.973l.001.002zm-3.786-.247c-.657-.76-1.368-1.58-2-2.308L3.759 6.952c.649.754 1.365 1.586 1.992 2.318l2.004-2.313z"
        />
      </svg>
    )
    ,
    description: 'Our simplified dashboard and user-friendly interface allows you to get reports without any hassle.'
  },
  {
    id: 2,
    title: 'Multiple profiles. One platform.',
    icon: <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={26}
      fill="none"
    >
      <path
        fill="#FA5C18"
        d="M23.891 8.646c.034.49-.296.715-.638.934-3.338 2.14-6.676 4.28-10.014 6.422-1.388.89-1.17.882-2.541.003-3.351-2.148-6.703-4.298-10.053-6.45-.857-.55-.86-1.252-.008-1.8C4.201 5.465 7.77 3.18 11.33.883c.442-.286.83-.289 1.27-.005 3.585 2.313 7.18 4.61 10.76 6.933.261.17.43.491.641.742l-.109.093Zm-2.545.022c-.098-.081-.143-.127-.196-.16C18.19 6.6 15.224 4.7 12.27 2.785c-.248-.16-.412-.12-.635.023C9.41 4.25 7.18 5.68 4.951 7.113L2.595 8.632c.058.057.078.085.103.102 3.001 1.931 6.001 3.864 9.013 5.78.13.084.4.071.539-.012.774-.467 1.528-.968 2.29-1.457l6.806-4.378ZM11.993 25.999c-.198-.092-.392-.159-.563-.264-3.628-2.239-7.255-4.48-10.88-6.723-.538-.332-.692-.823-.414-1.27.286-.459.806-.54 1.361-.198 3.385 2.088 6.772 4.177 10.149 6.28.3.187.502.181.8-.003 3.362-2.093 6.738-4.17 10.104-6.258.371-.23.77-.347 1.091-.046.211.195.395.575.353.837-.043.269-.327.564-.584.725-3.333 2.082-6.68 4.143-10.024 6.208-.282.174-.559.357-.85.514-.162.088-.348.13-.543.199v-.001Z"
      />
      <path
        fill="#FA5C18"
        d="M12.048 21.999c-.234-.095-.451-.148-.632-.26a6133.651 6133.651 0 0 1-10.862-6.72c-.542-.336-.696-.823-.42-1.267.292-.469.803-.553 1.366-.206 3.366 2.08 6.734 4.16 10.092 6.253.306.191.517.207.834.009 3.304-2.066 6.62-4.112 9.932-6.164.067-.041.134-.082.203-.122.516-.298 1.002-.213 1.291.225.283.429.156.931-.345 1.247-1.054.664-2.117 1.315-3.176 1.97-2.534 1.569-5.069 3.14-7.606 4.705-.208.129-.442.217-.676.331l-.001-.001Z"
      />
    </svg>,
    description: 'Get a full view of all your profiles at once. Run and track multiple profiles simultaneously across platforms with ease.'
  },
  {
    id: 3,
    icon: <svg width="23" height="30" viewBox="0 0 23 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_27_15)">
        <path d="M14.5363 10.6059C13.9239 12.2272 13.3329 13.7676 12.7739 15.3189C12.7304 15.44 12.8744 15.6996 13.005 15.8008C15.0987 17.4352 16.0014 19.5759 15.5885 22.1916C14.96 26.1771 10.7793 28.568 6.96637 27.184C3.97375 26.0963 2.14974 22.9017 2.79632 19.8758C3.51178 16.5276 6.45746 14.2914 9.76834 14.6142C10.1539 14.6516 10.2866 14.5269 10.4097 14.1979C11.7169 10.7079 13.0349 7.22195 14.3569 3.7375C14.715 2.79254 15.6035 2.46738 16.3742 2.99311C16.9052 3.35494 16.9833 3.89637 16.8796 4.52249C17.6481 4.81282 18.4023 5.09324 19.1522 5.38217C19.9464 5.68785 20.2847 6.36593 20.0148 7.08937C19.7475 7.80591 19.0379 8.11067 18.242 7.8393C17.8601 7.70858 17.4852 7.55677 17.1078 7.41422C16.7314 7.27203 16.356 7.13022 15.9452 6.97501L15.496 8.16408C16.2576 8.4518 16.9937 8.72651 17.7266 9.00677C18.5547 9.32412 18.9097 10.0028 18.6353 10.7381C18.3609 11.4734 17.6384 11.7677 16.8163 11.4672C16.0763 11.1967 15.3406 10.912 14.5346 10.6075L14.5363 10.6059ZM10.5602 17.4414C8.56036 16.6882 6.30948 17.6962 5.54857 19.6865C4.78394 21.6867 5.81724 24.003 7.80799 24.7506C9.78296 25.4921 12.0756 24.4571 12.8288 22.4842C13.5988 20.4668 12.5798 18.2021 10.5612 17.4418L10.5602 17.4414Z" fill="#FA5C18" />
      </g>
      <defs>
        <clipPath id="clip0_27_15">
          <rect width="26" height="13" fill="white" transform="translate(0.825218 24.864) rotate(-69.3045)" />
        </clipPath>
      </defs>
    </svg>
    ,
    title: 'User Access',
    description: 'Allow multiple users to access different profiles on the same account. This results in seamless collaboration between the team.'
  },
  {
    id: 4,
    title: 'Get metrics that matter',
    icon: <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={34}
      fill="none"
    >
      <g clipPath="url(#a)">
        <path
          fill="#FA5C18"
          d="M9.89 22.725c-1.615 1.277-3.247 1.346-4.507.211a3.235 3.235 0 0 1-.588-4.101c.684-1.098 2.3-2.082 4.31-1.088.158-.24.324-.487.485-.736.415-.644.805-1.304 1.247-1.927.425-.6.787-1.19.46-1.96a.343.343 0 0 1-.006-.237c.261-.635-.123-.946-.566-1.28-1.016-.766-2.005-1.566-3.02-2.363-.593.631-1.264 1.066-2.132 1.174-1.66.205-3.293-.994-3.535-2.634-.264-1.78.867-3.42 2.575-3.732 1.926-.352 3.701 1.119 3.757 3.1.005.182.07.427.197.53 1.22.995 2.46 1.966 3.688 2.938 1.7-1.199 3.272-1.167 4.489.079a3.214 3.214 0 0 1 .315 4.128c-1.016 1.414-2.554 1.673-4.49.724-.625.966-1.237 1.951-1.893 2.905-.254.37-.362.68-.164 1.122.138.307.216.703.153 1.025-.087.445.111.624.414.841 1.152.822 2.295 1.655 3.426 2.506.278.21.472.227.786.048 1.613-.914 3.58-.268 4.394 1.41.787 1.628.045 3.615-1.618 4.333-1.677.724-3.607-.126-4.24-1.865a3.035 3.035 0 0 1-.062-1.975c.076-.246.02-.367-.181-.51-1.127-.8-2.244-1.617-3.365-2.427l-.33-.237v-.002ZM6.01 20.522a1.48 1.48 0 0 0 1.457 1.51c.816.01 1.5-.646 1.52-1.457.018-.823-.675-1.543-1.49-1.544-.807-.002-1.478.673-1.486 1.49Zm9.953-7.556c-.005-.817-.678-1.493-1.484-1.491-.804.002-1.486.684-1.491 1.493-.005.818.719 1.546 1.516 1.526.802-.02 1.465-.714 1.459-1.528ZM6.627 7.239c0-.834-.642-1.492-1.462-1.497a1.514 1.514 0 0 0-1.507 1.474c-.014.812.703 1.543 1.508 1.535.801-.008 1.46-.688 1.461-1.512Zm10.16 21.045a1.516 1.516 0 0 0 1.501-1.478c.016-.81-.7-1.535-1.51-1.53-.76.004-1.474.741-1.474 1.52 0 .81.675 1.486 1.484 1.489v-.001Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 34V0h24v34z" />
        </clipPath>
      </defs>
    </svg>,
    description: 'Understand what works and doesn’t work for your brand. Make smart, data-backed decisions with detailed analytics.'
  },
  {
    id: 5,
    title: 'Platform-wise reports',
    icon: <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={26}
      fill="none"
    >
      <g fill="#FA5C18" clipPath="url(#a)">
        <path d="M10.05.38c.022.26.026.434.05.605.161 1.162-.126 1.83-1.401 2.356-4.345 1.795-6.767 6.365-6.13 11.093.614 4.548 4.268 8.233 8.886 8.966 4.53.718 9.185-1.769 11.138-5.948.819-1.752.904-1.794 2.925-1.446-.273 4.978-6.54 9.99-12.498 9.992C6.15 26.002.303 20.465.013 13.605-.307 6.016 5.46 1.066 10.05.38Z" />
        <path d="M26 12.915H13.101V.023C19.184-.408 26.073 5.198 26 12.915Zm-10.449-2.468h7.643c-.494-3.506-4.057-7.09-7.643-7.691v7.69Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h26v26H0z" />
        </clipPath>
      </defs>
    </svg>,
    description: 'Spending hours on your social media report? We’ll do it in seconds! Get automated reports with insightful observations and suggestions across platforms.'
  },
];

const WhyChooseUs = () => {
  return (
    <div className='md:mt-8 px-4 bg-rose-gradient py-12'>
    <h1 className='text-4xl md:text-6xl text-center'>Why choose us?</h1>
    <div className='block md:grid grid-cols-2 auto-cols-fr gap-6 px-4 md:px-24 my-10'>
      {cardDetails.map(card => {
        return <Card title={card.title} description={card.description} icon={card.icon} id={card.id} key={card.id} />
      })}
    </div>
    <h2 className='text-2xl md:text-4xl text-center p-8 mb-8'>To be seen, you need to see beyond likes and shares!</h2>
    <div className='text-center mb-10'>
      <TryforFreeButton content='Get a report now' type='big' />
    </div>
  </div>
  );
}

export default WhyChooseUs;
