import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import OpenRoutes from './OpenRoute';
import Landing from '../Pages/Landing';
import Pricing from '../Pages/Pricing';
import Terms from '../Pages/Terms';
import Privacy from '../Pages/Privacy';

const CustomRoute = () => (
  <Routes>
    <Route path="/" element={<OpenRoutes element={<Landing />} />} />
    <Route path="/pricing" element={<OpenRoutes element={<Pricing />} />} />
    <Route path="/terms-of-service" element={<Terms />} />
    <Route path="/privacy-policy" element={<Privacy />} />
    <Route path="*" element={<Navigate to='/' />} />
  </Routes>
);

export default CustomRoute;
