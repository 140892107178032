import React from 'react';

import Def from './Def';
import '../../../scss/terms.scss';

const TermsOfService = () => {
  React.useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <section className="terms-of-service">
      <div className="banner">Terms of Service</div>
      <div className="terms-content">
        <div className="space bold">
          <p className="content">
            PLEASE ENSURE THAT YOU READ AND UNDERSTAND ALL OF THESE CUSTOMER TERMS BEFORE YOU START USING THE WEBSITE, AS YOU WILL BE BOUND BY THESE CUSTOMER TERMS WHEN YOU CLICK “ACCEPT”.
          </p>
          <p className="content">
            IF YOU DO NOT AGREE WITH ANY OF THESE CUSTOMER TERMS, YOU MUST IMMEDIATELY CEASE ACCESSING AND USING THE WEBSITE AND THE SERVICES BEING PROVIDED UNDER THESE CUSTOMER TERMS. YOUR ACCEPTANCE OF THESE CUSTOMER TERMS WILL OPERATE AS A BINDING AGREEMENT BETWEEN YOU AND OUTSTRIP IN RESPECT OF YOUR USE OF THE WEBSITE, AND/OR SUBSCRIPTION OF AVAILABLE SERVICES.
          </p>
        </div>
        <p className="content">
          These Customer Terms are between You, and OUTSTRIP (“Company”), each referred to Individually as “Party” and collectively as “Parties”.
        </p>
        <div className="space">
          <h3 className="t-title">BACKGROUND</h3>
          <p className="content">
            Your use of the Platform and services and tools are governed by the following terms and conditions ("<b>Terms of Use</b>") as applicable to the Platform including the applicable policies which are incorporated herein by way of reference. If You transact on the Platform, You shall be subject to the policies that are applicable to the Platform for such transaction. By mere use of the Platform, You shall be contracting with Company and these terms and conditions including the policies constitute Your binding obligations, with Company.
          </p>
          <p className="content">
            For the purpose of these Terms of Use, wherever the context so requires; "You" or "User" shall mean any natural or legal person who has agreed to become a buyer of the services provided by the Platform by providing Registration Data while registering on the Platform as Registered User using computer systems/mobile devices/devices connected to the internet. Company allows the User a free trial of 15 days after which the User will have to purchase the product to continue using the Company’s services. The term "We", "Us", "Our" shall mean <b>Outstrip</b>.
          </p>
          <p className="content">
            When You use any of the services provided by Us through the Platform, including but not limited to, Scheduling posts, viewing reports etc. , You will be subject to the rules, guidelines, policies, terms, and conditions applicable to such service, and they shall be deemed to be incorporated into this Terms of Use and shall be considered as part and parcel of this Terms of Use. We reserve the right, at Our sole discretion, to change, modify, add or remove portions of these Terms of Use, at any time without any prior written notice to You. It is Your responsibility to review these Terms of Use periodically for updates / changes. Your continued use of the Platform following the posting of changes will mean that You accept and agree to the revisions. As long as You comply with these Terms of Use, We grant You a personal, non-exclusive, non-transferable, limited privilege to enter and use the Platform and its services.
          </p>
          <p>
            ACCESSING, BROWSING OR OTHERWISE USING THE SITE INDICATES YOUR AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS OF USE. By impliedly or expressly accepting these Terms of Use, You also accept and agree to be bound by Company Policies (including but not limited to Privacy Policy available at “_________” as amended from time to time.
          </p>
        </div>
        <Def />
      </div>
    </section>
  );
}

export default TermsOfService;
