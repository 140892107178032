import React from 'react';
import SubTerms from './SubTerms';

const defJson = [
  {
    id: '1',
    title: 'DEFINITIONS',
    contents: [
      {
        name: 'Unless the context requires otherwise, terms in these Customer Terms have the following meaning:',
        points: [
          {
            data: <><b>"Account"</b> means the account created by You on the Website and/or Application for accessing the Services.</>,
          },
          {
            data: <><b>“Affiliate”</b> means, in relation to any entity, another company or entity that either directly or indirectly, through one or more intermediaries, Controls, is Controlled by, or is under common Control with that entity. For purposes of this definition, “Control” means, with regard to any company or entity, (i) the legal or beneficial ownership, directly or indirectly, of 50% or more of the shares (or other ownership interests if not a corporation) of the entity or company through voting rights or through the exercise of rights pursuant to agreement; or (ii) the possession, directly or indirectly, of the power to direct or cause the direction of the management or policies of the entity or company.</>
          },
          {
            data: <><b>"Applicable Laws"</b> means (in any relevant jurisdiction) all laws, including rules of common law, principles of equity, statutes, regulations, proclamations, ordinances, by-laws, rules, statutory rules of an industry body, statutory and mandatory codes or guidelines or the like, writs, orders, injunctions, judgments applicable from time to time.</>
          },
          {
            data: <><b>"Application"</b> means such features of the <b>“OUTSTRIP”</b> mobile application or web application or other programs, software, mobile applications owned (or licensed to) by the Company, and other URLs as may be specified by the Company from time to time. The Application includes the electronic interface where the Subscriber account is accessible, the login credentials (user ID and password) for which will be provided by Company to the Subscriber.</>
          },
          {
            data: <><b>"Area of Operation"</b> means the area in which these Customer Terms are accepted by You, being throughout the world.</>
          },
          {
            data: <><b>"Brand Matter"</b> means an event that, in Company's reasonable judgement, causes it or its Affiliates to have concern for the reputation of its brand, including, but not limited to, matters related to the alleged violation of any Applicable Laws</>
          },
          {
            data: <><b>"Business Day"</b> means any day excluding Sunday in the Area of Operation.</>
          },
          {
            data: <><b>“Company Tools”</b> shall mean and include the website, application and other service functionalities including but not limited to inventory keeping and pricing along with software(s) owned and provided by the Company for the usage and benefit of the Merchant.</>
          },
          {
            data: <>
              <><b>“Confidential Information”</b> shall mean contents of this Agreement and all Communications, and with regard to a particular Party, means any information whether in written, oral, visual, electronic or other form that:</>
              <>(i) is disclosed and/or provided by a disclosing Party to such receiving Party and is clearly marked or indicated at the time of such disclosure or provision as confidential by the disclosing Party; provided that where there is no mark or indication of the confidential nature of such information at the time of disclosure, said information shall in any event be treated as confidential.</>
              <>(ii) is disclosed and/or provided by a disclosing Party to such receiving Party and (i) under normal social conventions should be treated in confidence, or (ii) concerns technical and/or trade secrets or other commercially sensitive information; </>
            </>
          },
          {
            data: <><b>“Customer”</b> means the general user, i.e., the end customer of the Company's Services who is not a Retailer or Re-Seller of Virtual Items and purchases the same for personal purposes;</>
          },
          {
            data: <><b>“Customer Content”</b> means images, sounds, videos, data, text, information or any other materials or content the Customer creates, uploads, publishes or propagates through the company’s services.</>
          },
          {
            data: <>
              <b>"Customer Feedback"</b> means information provided by a Customer in response to prompting by the Company App, including rating of Item(s) and comments or feedback related to the Customer’s experience with Merchant, the relevant Item(s) on the Company Website/ App, and the delivery services. 
            </>
          },
          {
            data: <>
              <b>“Company”</b> has the meaning given in the “Parties” section on the front page of these Customer Terms.
            </>
          },
          {
            data: <>
              <b>“Database”</b> means as any collection of data that is created, compiled, organized, or maintained by the company given by you. This may include customer information, product lists, or other data that is collected as part of a business's operations.
            </>
          },
          {
            data: <>
              <b>"Dispute"</b> means any dispute, action, claim, controversy or cause of action among the parties arising out of or in connection with the Agreement or any term condition or provision hereof, including without limitation any of the same relating to the existence, validity, interpretation, construction, performance, enforcement and termination of the Agreement.
            </>
          },
          {
            data: <>
              <b>“Disclosing Party”</b> shall mean the Company, OUTSTRIP INTERNET Private Limited.
            </>
          },
          {
            data: <>
              <b>“Intellectual Property"</b> or <b>“IP”</b> shall include ideas, concepts, creations, discoveries, inventions, improvements, know how, trade or business secrets, trademarks, service marks, designs, utility models, tools, devices, models, methods, procedures, processes, systems, schedules, principles, synthesis protocol, algorithms, works of authorship, flowcharts, drawings, books, papers, sketches, formulae, proprietary techniques, research projects, copyright, designs, reports, observations and other confidential and proprietary information, databases, data, documents, instruction manuals, records, memoranda, notes, user guides, in either printed or machine-readable form, whether or not copyrightable or patentable or protectable under any other intellectual property law, or any written or verbal instructions or comments;
            </>
          },
          {
            data: <>
              <b>“Linked sites”</b> means the links used in or through the services to other websites or services, provided solely as a convenience to the users.
            </>
          },
          {
            data: <>
              <b>“Material Default”</b> shall mean any event, occurrence, fact, condition, change, development or effect that, individually or in the aggregate, that has had or may reasonably be expected to have a material adverse effect on the ability of any of the Parties to carry out the scope of this Agreement contemplated herein or to perform its obligations hereunder;
            </>
          },
          {
            data: <>
              <b>“Permitted Information”</b> means information such as a user's name, username, address etc. which the user voluntarily submits to the website or application while registering for the same
            </>
          },
          {
            data: <>
              <b>“Prohibited Content”</b> means the images, sounds, videos, data, text, information or any other materials or content (collectively, “Content”) that: (a) violates any rule, law, regulation or policy; (b) harms national interests, endangers national security or divulges national secrets; (c) incites ethnic or racial discrimination or hatred; (d) undermines the social stability; (e) contains obscenity, sexual connotation, pornography, gambling, violence, murder, or terror; (f) insults or defames others or infringes others’ lawful rights and interests; or (g) contains abusive or threatening information.
            </>
          },
          {
            data: <>
              <b>“Privacy Policy”</b> means the privacy policy available on the website, as amended by the Company from time to time.
            </>
          },
          {
            data: <>
              <b>“Receiving Party”</b> shall mean either a Customer, or a Commercial Buyer or a Seller, as the case may be, in accordance with the provisions of this Policy.
            </>
          },
          {
            data: <>
              <b>“Registration Data”</b> means Subscriber name, email address, telephone number and other information (including personal data) that is provided to the Company for registering on the website and/or Application.
            </>
          },
          {
            data: <>
              <b>“Services”</b> means the technology services the Company or its affiliates provide such as “OUTSTRIP” or other applications/services operated by OUTSTRIP INTERNET Pvt. Ltd. and its affiliates. 
            </>
          },
          {
            data: <>
              <b>"Supplemental Terms"</b> means the Definitions, the General Supplemental Terms, the Specific Supplemental Terms and any other terms applicable to the use of the Company Services, Company Tools, and/or the Company mobile Application and shared with the Merchant.
            </>
          },
          {
            data: <>
              <b>“Virtual Items”</b> means any other form virtual currency (as updated by the Company on its website/application from time to time) which enables the user to purchase, use and engage in services offered on the company's app/website. These virtual items can be bought used, redeemed and/or purchased through the website/application or through the companies affiliate third party service providers.
            </>
          }
        ]
      }
    ]
  },
  {
    title: 'REGISTRATION AND USAGE',
    contents: [
      {
        name: <div><b>Authorized Users.</b> Your access to and use of the company's Services are subject to all applicable international, federal, state and local laws and regulations. Including GDPR regulations. You represent and warrant that you will not use the Service in any manner or for any purposes that are unlawful or prohibited by this Agreement.</div>
      },
      {
        name: 'Only use an authorized telephonic or internet network to access and use the Website.  When using the Website, the Subscription Services and the Services, standard messaging charges, data charges and/or voice charges (as applicable) may be imposed by Your Device provider and the same are Your responsibility.',
      },
      {
        name: 'You are solely responsible for maintaining the confidentiality of Your Registration Data and Application login credentials, and will be liable for all activities and transactions, and any other misuse of the Application, that occurs through Your Account (whether initiated by You or any third party), except to the extent caused or contributed by Company.'
      },
      {
        name: 'You must also notify the Company immediately if You cannot access Your Account, you know of or suspect any unauthorized access or use of Your Registration Data, login details or Account, or the security of Your Account has been compromised in any way.',
      },
      {
        name: 'The Company and/or any of its Third-Party Service Providers may suspend access and Service subscriptions entered into if:',
        points: [
          {
            data: 'The Registration Data or any other information provided by You is false, or You cease to satisfy the Eligibility Requirements;'
          },
          {
            data: 'The security of Your Account has been compromised in any way; or'
          },
          {
            data: 'You have not complied with any of the requirements in this clause. '
          }
        ]
      },
      {
        name: 'The Company and/or any of its Third-Party Service Providers may block, suspend, alter or update the Website, the Scheduling Services, the reporting services and/or the Services at any time (including without notice):',
        points: [
          {
            data: 'To make improvements to the Website, the Booking Services and/or the Services (including the security of the Website, the Booking Services and/or the Services);'
          },
          {
            data: 'as required by Applicable Law; or'
          },
          {
            data: 'To protect a legitimate business interest.'
          }
        ]
      }
    ]
  },
  {
    title: 'SPECIFIC FEATURES AND ADDITIONAL SERVICES.',
    contents: [
      {
        name: 'When using the company’s services, you may be subject to additional guidelines and/or terms and conditions (the “Guidelines”) as and when applicable, to specific services and features which may be posted from time to time on the platform.'
      },
      {
        name: 'All such Guidelines are hereby incorporated by reference into these Terms and Conditions of use. In addition, your use is further governed by the Company’s Privacy Policy available at https://outstrip.biz/privacy-policy (the “Privacy Policy”), which is hereby incorporated by reference into these Terms and Conditions and you consent to the collection, use and disclosure of any personal information in accordance with the Privacy Policy.'
      },
      {
        name: 'As mentioned above, if you are a Commercial Buyer and/or Seller of Virtual Items and are using this Platform in order to provide your services, please refer to the additional Buyer and Seller Terms which are applicable to you. The said terms which govern your usage of the Platform in addition to this General Terms and conditions of use will be provided upon request.'
      }
    ]
  },
  {
    title: 'LIMITED LICENSE.',
    contents: [
      {
        name: 'Subject to the terms and conditions herein, the Company hereby grants you a limited, revocable, non-transferable, non-exclusive and non-transferable license to use its website/application and other related services (including all the updates and/or upgrades as and when provided).'
      },
      {
        name: 'The said license is subject to the user adhering to these Terms and Conditions, Privacy Policy and any and all additional guidelines as and when updated by the Company.'
      }
    ]
  },
  {
    title: 'INTELLECTUAL PROPERTY RIGHTS',
    contents: [
      {
        name: 'The Website and all associated intellectual property rights (“IP”) remain the property of the Company or its third-party licensors. Except as expressly stated, nothing in these Customer Terms grants the Customer any rights in or related to the IP, and all rights not expressly granted to the Customer are reserved by the Company.'
      },
      {
        name: 'The website/application and other related services are owned and operated by the company. The content, visual interfaces, information, graphics, design, compilation, computer code, products, software, algorithms, services, and all other elements of the Services (hereinafter referred to as “Company Materials”) that are provided by the company are protected under the relevant Copyright, Trademark, Patent laws/statutes, international conventions, and all other relevant intellectual property rules and regulations.'
      },
      {
        name: 'All company materials contained on the website/application are the copyrighted property of OUTSTRIP INTERNET Private Limited or affiliated companies and/or third-party licensors.'
      },
      {
        name: 'The Company materials does not include any User/Customer Content or content from third party sites regardless of the fact that the company\'s website/application provides a link to access such content.'
      },
      {
        name: 'The Customer must not:',
        points: [
          {
            data: 'Copy, reproduce, modify, create derivative works of, decompile, reverse engineer, or attempt to derive the composition or underlying information, structure or ideas of, any IP;'
          },
          {
            data: 'Breach, disable, tamper with, or develop or use (or attempt) any workaround for any security measure provided on the website and/or Application;'
          },
          {
            data: 'Use any IP in a way that infringes or misappropriates a third party’s intellectual property rights or moral rights; '
          },
          {
            data: 'Distribute, disclose or allow use of any IP by any third party in any format, through any timesharing service, service bureau, network or by any other means; '
          },
          {
            data: 'Merge or combine any IP with any other technology not provided by the Company or any; or remove any proprietary notice language on any copies of any IP.'
          }
        ]
      },
      {
        name: 'The Customer is solely responsible for any notes, messages, e-mails, billboard postings, photos, drawings, profiles, opinions, ideas, images, videos, audio files or other material(s) or information posted or transmitted through the Website and/or Application or Device by or on behalf of the Customer (“Customer Content”).'
      },
      {
        name: 'The Customer must ensure that the Content is not unlawful and does not infringe any third party’s rights (including intellectual property rights), and the Customer must not:',
        points: [
          {
            data: `Publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful topic, blasphemous, pornographic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, or otherwise unlawful material or in-formation, or any material relating to or encouraging money laundering or gambling;`
          },
          {
            data: 'Upload files that contain software or other material protected by intellectual property laws (or by rights of privacy or publicity) unless You own or control the rights thereto or have received all necessary consents;'
          },
          {
            data: 'Upload files that contain viruses, corrupt files, or any other similar software or programs that may damage the operation of another computer or electronic device;'
          },
          {
            data: 'Download any file posted by another user that You know, or reasonably should know, cannot be legally distributed in such manner;'
          },
          {
            data: 'Falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded;'
          },
          {
            data: 'Deceive or mislead the addressee about the origin of a message or communicate any information which is grossly offensive or menacing in nature;'
          },
          {
            data: 'Harvest or otherwise collect information about others, including e-mail ad-dresses, without their consent.'
          }
        ]
      }
    ]
  },
  {
    title: 'USER CONTENT',
    contents: [
      {
        name: <div><b>Prohibited Content.</b> You shall not use the company’s services to create, upload, download, copy, publish or propagate any images, sounds, videos, data, text, information or any other materials or content (collectively, “Content”) that: (a) violates any rule, law, regulation or policy; (b) harms national interests, endangers national security or divulges national secrets; (c) incites ethnic or racial discrimination or hatred; (d) undermines the social stability; (e) contains obscenity, sexual connotation, pornography, gambling, violence, murder, or terror; (f) insults or defames others or infringes others' lawful rights and interests; or (g) contains abusive or threatening information.</div>
      },
      {
        name: <div><b>License to User Content.</b> You hereby grant the Company a worldwide, non-exclusive, irrevocable, royalty-free, fully-paid, perpetual, sub licensable (through multiple tiers), fully transferable license to use, distribute, reproduce, create derivative works from, publish, translate, publicly perform and publicly display any Content that you upload or publish to the company’s services (collectively, “Your Content”), in any format or medium now known or later developed for any purpose. Outstrip Internet Pvt. Ltd. can use the customer/user content in/on the website/mobile application and in other products or services, at the company’s sole discretion and can transfer the license or authorization of using such information and content to its related companies and partners once again, at their sole discretion without the need to obtain the users consent. </div>
      },
      {
        name: 'The Company reserves the right to display advertisements/promotional material(s) and sponsorships in connection with User/Customer Content.'
      },
      {
        name: 'The company has the right to remove, at its sole discretion and without notice to you, Your Content, if it infringes others’ rights and interests. The company further has the right to suspend or terminate a user’s access to the services if the same is in violation of copyright law or other intellectual property law.'
      },
      {
        name: 'All liabilities and damage with respect to any and all claim(s) raised by the said third party(s) with respect to Customer/User content shall be assumed by the user and they shall compensate the Company for all losses and damages it incurs therefrom, including but not limited to economic losses and business losses.'
      },
      {
        name: 'You should not, send any confidential or proprietary information to the Company, unless required as per the applicable laws, notwithstanding the “Permitted Information” (as per clause 1.1.12) already submitted by you.'
      },
      {
        name: 'Subject to the Company handling your personal information in accordance with the existing Privacy Policy, none of your Content will not be considered confidential or proprietary.'
      }
    ]
  },
  {
    title: 'USER OBLIGATIONS.',
    contents: [
      {
        name: <div><b>Restrictions:</b> As a pre-condition to your use of the Service; you will not use the Services for any purpose that is unlawful or prohibited by these Terms and Conditions herein.</div>
      },
      {
        name: 'Access to the Company Materials and connected services from territories wherein the same is illegal is strictly prohibited. Users are responsible for complying with all local rules, laws, regulations and policies, including, without limitation, rules with respect to intellectual property rights, the internet, technology, data, email, and/or privacy.'
      },
      {
        name: 'Use of any of the Company Materials for any other purpose apart from what is permitted herein is prohibited.'
      },
      {
        name: 'You will not use the company service in any manner that, in our sole discretion could damage, disable, overburden, impair or interfere with any other party’s use of the Service. '
      },
      {
        name: 'You will not take any action that imposes an unreasonable or disproportionately large load on the company’s infrastructure. '
      },
      {
        name: 'You will not intentionally interfere with or damage the operation of the company’s services or any user’s enjoyment of it, by any means, including uploading or otherwise disseminating viruses, worms, or other malicious code. '
      },
      {
        name: 'You will not remove, circumvent, disable, damage or otherwise interfere with any security-related features of the company’s services, features that prevent or restrict the use or copying of any content accessible, or features that enforce limitations on the use of the company’s services.'
      },
      {
        name: 'You will not attempt to gain unauthorized access to the company’s services, or any part of it, other accounts, computer systems or networks connected to it or any part of it, through hacking, password mining or any other means or try to interfere or attempt to interfere with the proper working of the company’s service and/or the activities conducted on the company’s services. '
      },
      {
        name: 'You will not obtain or attempt to obtain any materials or information through any means not intentionally made available by the company. You agree neither to modify in any manner or form, nor to use modified versions of the company’s services, including (without limitation) for the purpose of obtaining unauthorized access to the same'
      },
      {
        name: 'The company services may contain an auto-reply function.  You agree that you will not use any robot, spider, scraper, or other automated means to access, bypass the company’s services for any purpose without our express written permission. '
      },
      {
        name: 'You will not utilize framing techniques to enclose any trademark, logo, or other company materials without our express written consent. '
      },
      {
        name: 'You will not use any meta tags or any other “hidden text” utilizing the company’s name or trademarks without our express written consent.'
      },
      {
        name: 'A User hereby agrees, undertakes, and confirms that User’s use of Platform shall be strictly governed by the following binding principles:',
        points: [
          {
            data: 'User shall not host, display, upload, modify, publish, transmit, store, update or share any information which:',
            subpoints: [
              'belongs to another person and to which User does not have any right to;',
              `is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another's privacy including bodily privacy, hateful, racially or ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever; or unlawfully threatening or unlawfully harassing including but not limited to "indecent representation of women" within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986 or otherwise inconsistent with or contrary to the laws in force;`,
              `is misleading in any way;`,
              `is patently offensive to the online community, such as sexually explicit content, or content that promotes obscenity, paedophilia, racism, bigotry, hatred or physical harm of any kind against any group or individual;`,
              `harasses or advocates harassment of another person;`,
              `involves the transmission of "junk mail", "chain letters", or unsolicited mass mailing or "spamming" or messages using the Company’s communication Platform;`,
              `promotes illegal activities or conduct that is abusive, threatening, obscene, defamatory or libellous;`,
              `infringes upon or violates any third party's rights including, but not limited to, intellectual property rights, rights of privacy (including without limitation unauthorized disclosure of a person's name, email address, physical address or phone number) or rights of publicity;`,
              `promotes an illegal or unauthorized copy of another person's copyrighted work, such as providing pirated computer programs or links to them, providing information to circumvent manufacture-installed copy-protect devices, or providing pirated music or links to pirated music files;`,
              `contains restricted or password-only access pages, or hidden pages or images (those not linked to or from another accessible page);`,
              `provides material that exploits people in a sexual, violent or otherwise inappropriate manner or solicits personal information from anyone;`,
              `provides instructional information about illegal activities such as making or buying illegal weapons, violating someone's privacy, or providing or creating computer viruses;`,
              `contains video, photographs, or images of another person (with a minor or an adult);`,
              `tries to gain unauthorized access or exceeds the scope of authorized access to the Platform or to profiles, blogs, communities, account information, bulletins, friend request, or other areas of the Platform or solicits passwords or personal identifying information for commercial or unlawful purposes from other Users;`,
              `interferes with another user’s use and enjoyment of the Platform or enjoyment of any similar Services;`,
              `refers to any website or URL that, in sole discretion, contains material that is inappropriate for the Platform or any other website, contains content that would be prohibited or violates the letter or spirit of these Terms;`,
              `harm minors in any way;`,
              `infringes any patent, trademark, copyright or other proprietary rights or third party's trade secrets or rights of publicity or privacy or shall not be fraudulent or involve the sale of counterfeit or stolen products;`,
              `violates any law for the time being in force;`,
              `deceives or misleads the addressee/ users about the origin of such messages or communicates any information which is grossly offensive or menacing in nature or knowingly and intentionally communicates any information which is patently false or misleading in nature but may reasonably be perceived as a fact;`,
              `impersonate another person;`,
              `contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource; or contains any trojan horses, worms, time bombs, cancel-bots, easter eggs or other computer programming routines that may damage, detrimentally interfere with, diminish value of, surreptitiously intercept or expropriate any system, data or personal information;`,
              `threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation.`,
              `shall not be false, inaccurate or misleading;`,
              `is patently false and untrue, and is written or published in any form, with the intent to mislead or harass a person, entity or agency for financial gain or to cause any injury to any person.`
            ]
          }
        ]
      },
      {
        name: 'In addition to the above, You shall not use any "deep-link", "page-scrape", "robot", "spider" or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Platform or any content on the Platform, or in any way reproduce or circumvent the navigational structure or presentation of the Platform or any content on the Platform, to obtain or attempt to obtain any materials, documents or information through any means not purposely made available through the Platform. OUTSTRIP RESERVES ITS RIGHT TO BAR ANY SUCH ACTIVITY.'
      },
      {
        name: 'You shall not make any defamatory or denigrating statement(s) about OUTSTRIP, or our brand name or domain name, as used by OUTSTRIP including usage of the terms OUTSTRIP, or otherwise act in any manner that might tarnish the reputation or standing, of OUTSTRIP or Users on the Platform or otherwise tarnish or dilute any of the Company\'s trademarks, service marks, trading name, or the goodwill associated with them.'
      },
      {
        name: 'You shall at all times ensure full compliance with the applicable provisions of the Information Technology Act, 2000 and rules and the Company undertakes to be compliant with the DPDP Act, 2023 thereunder as applicable and as amended from time to time.'
      },
      {
        name: 'User shall not attempt to gain unauthorized access to any portion or feature of the Platform, or any other systems or networks connected to the Platform or to any server, computer, network, or to any of the Services offered on or through the Platform, by hacking, password "mining" or any other illegitimate means.'
      },
      {
        name: 'Unless expressly permitted, User shall not probe, scan or test the vulnerability of the Platform or any network connected to the Platform nor breach the security or authentication measures on the Platform or any network connected to the Platform. User shall not reverse look-up, trace or seek to trace any information on any other User of or visitor to Platform, or any other User, including any account on the Platform not owned by User, to its source, or exploit the Platform or any service or information made available or offered by or through the Platform, in any way where the purpose is to reveal any information, including but not limited to personal identification or information, other than User’s own information, as provided for by the Platform.'
      },
      {
        name: 'By accepting these terms and using the Platform as provided by the Company, each User agrees to indemnify OUTSTRIP, its affiliates, directors, employees, agents and representatives and to hold them harmless, from any and all damages, losses, claims and liabilities (including legal costs on a full indemnity basis) which may arise from or in connection with: (i) your submission, posting or display of any User Content; (ii) from your use of the Platform or Services; (iii) from your breach of the Terms or breach of any applicable laws, including tax laws and other terms and conditions which may apply to you; (iv) any service availed by you from a third party service provider using any dispute inter-se Users; and/or (vi) your negligence or wilful misconduct.'
      },
      {
        name: <div>Each User shall at all times, adhere to <span className="error">our Code of Conduct at all times.</span></div>
      },
      {
        name: <div><b>Representation and Warranty:</b> You represent, warrant and covenant that at all times: (i) the Your Content is legal, does not infringe any third party’s intellectual property, right of reputation, right of name, right of privacy, moral rights and other lawful rights and interests; (ii) you own or have the necessary licenses, rights, consents and permissions for the use of your Content; (iii) you authorise the company’s use of your content for its purposes.</div>
      }
    ]
  },
  {
    title: 'FEEDBACK, ISSUES AND COMPLAINTS',
    contents: [
      {
        name: 'You may, but are not required to, provide suggestions, comments, ideas, or know-how, in any form with respect to the services to the company.'
      },
      {
        name: 'Any Feedback shall not be considered your confidential information and may be used by the company for any purpose. '
      },
      {
        name: 'There shall be no obligation to provide compensation for use of Feedback.'
      },
      {
        name: 'All feedbacks issues and complaints are to be sent to the Company via the following email: deshik@outstrip.biz.'
      }
    ]
  },
  {
    title: 'THIRD PARTY SITES.',
    contents: [
      {
        name: <div>The Service may include links to other websites or services solely as a convenience to users <b>(“Linked Sites”)</b>.</div>
      },
      {
        name: 'The Company does not endorse any Linked Sites or the information, material, products or services contained on Linked Sites or accessible through Linked Sites. Furthermore, the company makes no express or implied warranties with regard to the information, material, products, or services that are contained on or accessible through Linked Sites. '
      },
      {
        name: 'Access and use of linked sites, including the information, material, products, and services on linked sites or available through linked sites, is solely at your own risk.'
      },
      {
        name: 'Your correspondence or business dealings and/or participation and/or engagement with the advertisers/third party(s) found on or through the platform are solely between you and such advertiser. You agree that the company will not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or as the result of the presence of such advertisers on its website/application or platform.'
      },
      {
        name: 'The company may make changes to or discontinue any of the content or services available on the Platform/website/mobile application at any time, without notice. The content or services may be out of date, and the company makes no commitment to update these materials.'
      }
    ]
  },
  {
    title: 'FORCE MAJEURE',
    contents: [
      {
        name: 'Any delay in or failure to perform any obligations by a Party under these Customer Terms will not constitute a breach of these Customer Terms to the extent caused by acts of any government authorities, acts of God, fire, flood, explosion, pandemic(s), riots, war, rebellion, insurrection or other event beyond the reasonable control of that Party (“Force Majeure”).'
      }
    ]
  },
  {
    title: 'TERMINATION',
    contents: [
      {
        name: 'These Customer Terms continues until such time as they are terminated by the company in accordance with terms hereunder.'
      },
      {
        name: 'The Company may terminate these Terms with immediate effect without notice;',
        points: [
          {
            data: 'In order to comply with any Applicable Law;'
          },
          {
            data: 'You commit a breach of these or any other Customer Terms and Conditions/Policies/guidelines.'
          }
        ]
      },
      {
        name: 'You agree that the Company may at its sole discretion may terminate any account (or any part thereof). In addition, the company reserves the right to discontinue any aspect of the Service at any time, including but not limited to the right to discontinue the display of any licensed content, linked or embedded content etc. '
      },
      {
        name: 'The company shall in no event be responsible for the deletion, losing of, or failure to store your content. You agree that any termination of your access to the Service or any account you may have or portion thereof may be affected without prior notice, and you agree that the company will not be liable to you or any third party for such termination. '
      },
      {
        name: 'Any suspected fraudulent, abusive, or illegal activity that may be grounds for termination may be referred to appropriate law enforcement authorities. These remedies are in addition to any other remedies the company may have in law or in equity.'
      }
    ]
  },
  {
    title: 'DISCLAIMERS; NO WARRANTIES.',
    contents: [
      {
        name: 'Without limiting any other provision of this section and in addition to all other provisions of this section, to the fullest extent permitted by applicable law, the company expressly disclaims all warranties and conditions of any kind, either express or implied, including without limitation any implied warranties or conditions of merchantability, fitness for a particular purpose, non-infringement of third party rights, and those arising from a course of dealing or usage of trade, with respect to the services. The company makes no warranty that the services will meet your requirements, or that they will be uninterrupted, timely, secure, or error free. The company does not make any warranty or representation as to the use or the results that may be obtained from the use of their services.'
      },
      {
        name: 'You acknowledge that the services may be subject to operating errors or defects including, but not limited to loss of data, delays, non-deliveries, errors, system down time, mis-deliveries, network or system outages, file corruption, or service interruptions. No such event shall constitute a breach of this or any other contract on the part of the company, even if caused by the negligence or gross negligence of the company or any of its affiliates, employees, agents, licensors or subcontractors.'
      },
      {
        name: 'Certain state laws do not allow limitations or implied warranties or the exclusion or limitation of certain damages. If these laws apply to you, some or all of the above disclaimers, exclusions, or limitations may not apply to you, in such cases, the company’s liability will be limited to the extent permitted by law.'
      }
    ]
  },
  {
    title: 'INDEMNIFICATION.',
    contents: [
      {
        name: 'You agree to indemnify and hold the company, and its affiliated companies, and its suppliers and partners, harmless from any claims, losses, damages, liabilities, including attorney’s fees, arising out of your use or misuse of the Service, violation of these Terms, violation of the rights of any other person or entity, or any breach of the foregoing representations, warranties, and covenants.'
      },
      {
        name: 'The company reserves the right, at our own expense, to assume the exclusive defence and control of any matter for which you are required to indemnify us and you agree to cooperate and indemnify us in our defence of these claims.'
      }
    ]
  },
  {
    title: 'LIMITATION OF LIABILITY',
    contents: [
      {
        name: 'Under no circumstances, including, but not limited to, negligence, shall the company or its affiliates, contractors, employees, agents, or third party partners or suppliers, be liable to you for any special, indirect, incidental, consequential, or exemplary damages that result from your use or the inability to use the company materials or the service itself, or any other interactions with the company, even if the company or an authorized representative has been advised of the possibility of such damages. Applicable law may not allow the limitation or exclusion of liability or incidental or consequential damages, so the above limitation or exclusion may not apply to you. In such cases, the company’s liability will be limited to the extent permitted by law.'
      },
      {
        name: 'The Company will delete all user data after 45 days of the User not continuing with their subscription. The Company does not and will not sell, share, give to third parties any data of the User.'
      },
      {
        name: `In no event shall the company or its affiliates, contractors, employees, agents, or third-party partners or suppliers' total liability to you for all damages, losses, and causes of action arising out of or relating to these terms or your use of the company services (whether in contract, tort, warranty, or otherwise) exceed the amount already spent by a particular user on the platform.`
      },
      {
        name: 'These limitations shall also apply with respect to damages incurred by reason of any products or services sold or provided to you by third parties other than the company and received by you through or advertised on the company service or received by you through any links provided on the company service.'
      }
    ]
  },
  {
    title: 'AMENDMENTS TO THESE CUSTOMER TERMS',
    contents: [
      {
        name: 'The Company may amend the terms of these Customer Terms and shall keep you updated regarding such amendments.'
      },
      {
        name: 'We may change these Terms at any time by updating them on the Website. Unless stated otherwise, any change takes effect immediately. You are responsible for ensuring you are familiar with the latest Terms. By continuing to access and use the Website, you agree to be bound by the changed Terms.'
      },
      {
        name: 'We may change, suspend, discontinue, or restrict access to, the Website without notice or liability.'
      },
      {
        name: 'These Terms were last updated on 27.04.2023'
      }
    ]
  },
  {
    title: 'GOVERNING LAW AND DISPUTE RESOLUTION',
    contents: [
      {
        name: 'This Agreement shall be governed by the law of the Republic of India.'
      },
      {
        name: 'In the event of a dispute arising out of this Agreement, the parties agree to attempt to resolve any dispute by negotiation between the parties. If they are unable to resolve the dispute, either party may commence mediation and/or binding arbitration under the Indian Arbitration and Conciliation Act, 1996 including any amendments thereto. The prevailing party in any dispute resolved by binding arbitration or litigation shall be entitled to recover its attorneys’ fees and costs. Courts in Bangalore shall have exclusive jurisdiction with respect to the arbitration proceedings.'
      },
      {
        name: 'These Terms will be governed by and construed in accordance with the laws of the State of Karnataka and you submit to the exclusive jurisdiction of the courts located in Bengaluru for the resolution of any disputes.'
      }
    ]
  },
  {
    title: 'MISCELLANEOUS',
    contents: [
      {
        name: <div><b>Waiver:</b> The provision(s) of these Terms may be waived only by a written instrument executed by the party entitled to the benefit of such provision. The failure of any party at any time to require performance of any provision of these Terms shall in no manner affect such party’s right at a later time to enforce the same. A waiver of any breach of any provision of these Terms shall not be construed as a continuing waiver of other breaches of the same or other provisions of these Terms.</div>
      },
      {
        name: <div><b>Assignment:</b> The Company shall be permitted to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification or consent. However, you shall not be permitted to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.</div>
      },
      {
        name: <div><b>Severability:</b> Any provision that is prohibited or unenforceable in any jurisdiction will, as to such jurisdiction, be ineffective to the extent of such prohibition or unenforceability without invalidating the remaining provisions or affecting the validity or enforceability of such provision in any other jurisdiction.</div>
      },
      {
        name: <div><b>No warranties:</b> This Website is provided “as is,” with all faults, and the company makes no express or implied representations or warranties, of any kind related to this Website or the materials contained on this Website. Additionally, nothing contained on this Website shall be construed as providing consult or advice to you.</div>
      },
      {
        name: <div><b>Rights cumulative:</b> Subject to any express provision in these Customer Terms to the contrary, the rights, powers or remedies of a Party under these Customer Terms are cumulative and in addition to, and do not exclude or limit, any right, power or remedy in any other part of these Customer Terms or otherwise provided at law or in equity.</div>
      },
      {
        name: <div><b>Notice.</b> Any notice will be sent to the address of the relevant party as provided to the Company and deemed duly given: (a) upon actual delivery, if delivery is by hand; or (b) three (3) days after being sent by overnight courier, charges prepaid; or (c) by electronic mail to the designated recipient to their registered E-mail address.</div>
      }
    ]
  }
]

const Def = () => {
  return <SubTerms data={defJson} />;
}

export default Def;
