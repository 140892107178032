import React from 'react';

const renderTitleComp = (title, icon) => (
  <div className='flex items-center text-os-red'>
    <div className='w-4'>{icon}</div>
    <p className='ml-4 text-base md:text-xl'>{title}</p>
  </div>
);

const renderDescription = (description) => (
  <div className='py-2'>
    <p className="text-gray-500 font-['JostLight'] text-base md:text-xl">{description}</p>
  </div>
);

const Card = ({ title, icon, description, id }) => {
  const colClass = id === 5 ? 'col-span-2' : 'col-span-1'
  return (
    <div className={`my-6 md:my-1 px-6 md:px-12 py-8 md:py-16 border-none rounded-3xl bg-white ${colClass}`}>
      {renderTitleComp(title, icon)}
      {renderDescription(description)}
    </div>
  );
}

export default Card;
