import React from 'react';

import PricingPointIconImg from '../../../images/pricing-point-icon.png';

const renderStandardOnePlan = () => (
  <div className='bg-[#FFF5F1] px-8 py-12 border-none rounded-3xl w-auto md:w-96 h-auto md:h-[540px] mt-4 md:mt-8'>
    <h2 className="text-2xl md:text-5xl text-os-red font-['JostLight'] mb-4 md:mb-12">Basic</h2>
    <p className='pb-12'>
      <span className='font-bold text-2xl md:text-4xl'>$50</span>
      <span className='ml-1'>per year</span>{' '}<span className='font-[JostLight]'>(1 brand)</span>
    </p>
    <div>
      <ul className='list-disc list-inside text-base md:text-xl'>
        <li>Manage up to 4 social profiles</li>
        <li className='font-[JostLight] text-sm list-none ml-7'>(Facebook, Instagram, LinkedIn, YouTube)</li>
        <li>Instant, automated</li>
        <li>Platform-specific reports</li>
        <li>Comparative reports</li>
        <li>Consolidated reports</li>
      </ul>
    </div>
    <div className='text-center'>
      <div className='inline-block mt-8 bg-gradient border-none rounded-full p-0.5'>
        <a className='inline-block text-xl md:text-2xl bg-white h-4/5 m-auto px-6 md:px-11 py-1 md:py-3 border-none rounded-full' href='https://app.outstrip.biz/sign-up?planType=basic'>
          <span className='text-gradient'>Start free trial</span>
        </a>
      </div>
    </div>
  </div>
);

const renderStandardPlan = () => (
  <div className='bg-[#FFF5F1] px-8 py-12 border-none rounded-3xl w-auto md:w-96 h-auto md:h-[540px] mt-4 md:mt-8'>
    <h2 className="text-2xl md:text-5xl text-os-red font-['JostLight'] mb-4 md:mb-12">Standard</h2>
    <p className='pb-12'>
      <span className='font-bold text-2xl md:text-4xl'>$120</span>
      <span className='ml-1'>per year </span>{' '}<span className='font-[JostLight]'>(5 brands)</span>
    </p>
    <div>
      <ul className='list-disc list-inside text-base md:text-xl'>
        <li>Manage up to 20 social profiles</li>
        <li className='font-[JostLight] text-sm list-none ml-7'>(Facebook, Instagram, LinkedIn, YouTube)</li>
        <li>Instant, automated</li>
        <li>Platform-specific reports</li>
        <li>Comparative reports</li>
        <li>Consolidated reports</li>
      </ul>
    </div>
    <div className='text-center'>
      <div className='inline-block mt-8 bg-gradient border-none rounded-full p-0.5'>
        <a className='inline-block text-xl md:text-2xl bg-white h-4/5 m-auto px-6 md:px-11 py-1 md:py-3 border-none rounded-full' href='https://app.outstrip.biz/sign-up?planType=pro'>
          <span className='text-gradient'>Start free trial</span>
        </a>
      </div>
    </div>
  </div>
);


const renderBusinessPlan = () => (
  <div className='bg-card-gradient p-12 border-none rounded-3xl w-auto md:w-96 h-auto md:h-[540px] mt-4 md:mt-8'>
    <h2 className='text-gradient text-2xl md:text-5xl mb-4 md:mb-12'>Business</h2>
    <ul className='list-disc list-inside text-base md:text-xl mb-16'>
      <li>Everything in Basic and Standard</li>
      <li>With Observations & insights</li>
    </ul>
    <div className='text-xl md:text-2xl text-os-orange'>
      <div>Add on</div>
      <div>For every extra brandset basic $12</div>
    </div>
    <div className='mt-14'>
      <a className='bg-gradient text-white text-xl md:text-2xl px-4 md:px-8 py-2 md:py-4 border-none rounded-full' href='mailto:hello@outstrip.biz'>Contact Sales</a>
    </div>
  </div>
);

const Pricing = () => {
  return (
    <div className='p-6 md:py-12 md:px-14 md:ml-24' id='pricingContainer'>
      <h1 className='text-4xl md:text-8xl text-gradient'>Outstrip for your business</h1>
      <div className='text-3xl py-8 md:py-12 flex items-center'>
        <img src={PricingPointIconImg} alt='point icon' loading='lazy' />
        <span className='ml-3 text-xl'>Try at no cost for 14 days</span>
      </div>
      <div className='lg:flex items-start gap-4'>
        {renderStandardOnePlan()}
        {renderStandardPlan()}
        {renderBusinessPlan()}
      </div>
      <div className='text-3xl py-8 md:py-12 flex items-center'>
        <img src={PricingPointIconImg} alt='point icon' loading='lazy' />
        <span className='ml-3 text-xl font-[JostLight]'>A brand includes one Facebook, Instagram, YouTube, and LinkedIn account each</span>
      </div>
    </div>
  );
}

export default Pricing;
