import React from 'react';

import Hero from './Hero';
import WhyChooseUs from './WhyChooseUs';
import SocialMediaIntegration from './SocialMediaIntegrations';
import Testimonials from './Testimonials';
import Pricing from '../Pricing';
import WeValuePrivacy from './WeValuePrivacy';


const Landing = () => {
  return (
    <React.Fragment>
      <Hero />
      <SocialMediaIntegration />
      <WhyChooseUs />
      <Testimonials />
      <Pricing />
      <WeValuePrivacy />
    </React.Fragment>
  );
}

export default Landing;
