import React from 'react';

import '../../../scss/privacy.scss';

const PrivacyPolicy = () => {
  React.useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <section className="privacy-policy">
      <div className="banner">Outstrip Privacy Policy</div>
      <div className="privacy-content">
        <div className="space">Privacy Policy</div>
        <div>Last updated: July 09, 2020 </div>
        <div className="space">
          This Privacy Policy describes Our policies and procedures on the collection, use and
          disclosure of Your information when You use the Service and tells You about Your privacy
          rights and how the law protects You.
        </div>
        <div className="space">
          We use Your Personal data to provide and improve the Service. By using the Service, You
          agree to the collection and use of information in accordance with this Privacy Policy. This
          Privacy Policy has been created with the help of the Privacy Policy Generator.
        </div>
        <div className="space">Interpretation and Definitions</div>
        <div>Interpretation</div>
        <div>
          The words of which the initial letter is capitalized have meanings defined under the
          following conditions. The following definitions shall have the same meaning regardless of
          whether they appear in singular or in plural.
        </div>
        <div className="space">Definitions</div>
        <div>For the purposes of this Privacy Policy:</div>
        <div className="space">
          You means the individual accessing or using the Service, or the company, or other legal
          entity on behalf of which such individual is accessing or using the Service, as applicable.
        </div>
        <div className="space">
          Company (referred to as either &ldquo;the Company&rdquo;, &ldquo;We&rdquo;, &ldquo;Us&rdquo;
          or &ldquo;Our&rdquo; in this Agreement) refers to Outstrip
        </div>
        <div className="space">
          Affiliate means an entity that controls, is controlled by or is under common control with a
          party, where &ldquo;control&rdquo; means ownership of 50% or more of the shares, equity
          interest or other securities entitled to vote for election of directors or other managing
          authority.
        </div>
        <div className="space">
          Account means a unique account created for You to access our Service or parts of our
          Service.
        </div>
        <div className="space">
          Website refers to
          <a href="https://outstrip.biz/" target="_blank" rel="noopener noreferrer" className="link">
            https://outstrip.biz
          </a>
          accessible from Outstrip
        </div>
        <div className="space">Service refers to the Website.</div>
        <div className="space">Country refers to: Karnataka, India</div>
        <div className="space">
          Service Provider means any natural or legal person who processes the data on behalf of the
          Company. It refers to third-party companies or individuals employed by the Company to
          facilitate the Service, to provide the Service on behalf of the Company, to perform services
          related to the Service or to assist the Company in analyzing how the Service is used.
        </div>
        <div className="space">
          Third-party Social Media Service refers to any website or any social network website through
          which a User can log in or create an account to use the Service.
        </div>
        <div className="space">
          Personal Data is any information that relates to an identified or identifiable individual.
        </div>
        <div className="space">
          Cookies are small files that are placed on Your computer, mobile device or any other device
          by a website, containing the details of Your browsing history on that website among its many
          uses.
        </div>
        <div className="space">
          Device means any device that can access the Service such as a computer, a cellphone or a
          digital tablet.
        </div>
        <div className="space">
          Usage Data refers to data collected automatically, either generated by the use of the
          Service or from the Service infrastructure itself (for example, the duration of a page
          visit).
        </div>
        <div className="space">Collecting and Using Your Personal Data </div>
        <div>Types of Data Collected</div>
        <div>Personal Data</div>
        <div>
          While using Our Service, We may ask You to provide Us with certain personally identifiable
          information that can be used to contact or identify You. Personally identifiable information
          may include, but is not limited to:
        </div>
        <div className="space">Email address</div>
        <div className="space">First name and last name</div>
        <div className="space">Phone number</div>
        <div className="space">Usage Data </div>
        <div className="space">Usage Data is collected automatically when using the Service. </div>
        <div className="space">
          Usage Data may include information such as Your Device&apos;s Internet Protocol address
          (e.g. IP address), browser type, browser version, the pages of our Service that You visit,
          the time and date of Your visit, the time spent on those pages, unique device identifiers
          and other diagnostic data.
        </div>
        <div className="space">
          When You access the Service by or through a mobile device, We may collect certain
          information automatically, including, but not limited to, the type of mobile device You use,
          Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating
          system, the type of mobile Internet browser You use, unique device identifiers and other
          diagnostic data.
        </div>
        <div className="space">
          We may also collect information that Your browser sends whenever You visit our Service or
          when You access the Service by or through a mobile device.
        </div>
        <div className="space">Tracking Technologies and Cookies</div>
        <div>
          We use Cookies and similar tracking technologies to track the activity on Our Service and
          store certain information. Tracking technologies used are beacons, tags, and scripts to
          collect and track information and to improve and analyze Our Service.
        </div>
        <div className="space">
          You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being
          sent. However, if You do not accept Cookies, You may not be able to use some parts of our
          Service.
        </div>
        <div className="space">
          Cookies can be &ldquo;Persistent&rdquo; or &ldquo;Session&rdquo; Cookies. Persistent Cookies
          remain on your personal computer or mobile device when You go offline, while Session Cookies
          are deleted as soon as You close your web browser. Learn more about cookies: All About
          Cookies.
        </div>
        <div className="space">
          We use both session and persistent Cookies for the purposes set out below:
        </div>
        <div className="space">Necessary / Essential Cookies</div>
        <div className="space">Type: Session Cookies </div>
        <div className="space">Administered by: Us</div>
        <div className="space">
          Purpose: These Cookies are essential to provide You with services available through the
          Website and to enable You to use some of its features. They help to authenticate users and
          prevent fraudulent use of user accounts. Without these Cookies, the services that You have
          asked for cannot be provided, and We only use these Cookies to provide You with those
          services.
        </div>
        <div className="space">Cookies Policy / Notice Acceptance Cookies </div>
        <div className="space">Type: Persistent Cookies</div>
        <div className="space">Administered by: Us</div>
        <div className="space">
          Purpose: These Cookies identify if users have accepted the use of cookies on the Website.
        </div>
        <div className="space">Functionality Cookies</div>
        <div className="space">Type: Persistent Cookies</div>
        <div className="space">Administered by: Us</div>
        <div className="space">
          Purpose: These Cookies allow us to remember choices You make when You use the Website, such
          as remembering your login details or language preference. The purpose of these Cookies is to
          provide You with a more personal experience and to avoid You having to re-enter your
          preferences every time You use the Website.
        </div>
        <div className="space">
          For more information about the cookies we use and your choices regarding cookies, please
          visit our Cookies Policy or the Cookies section of our Privacy Policy.
        </div>
        <div className="space">Use of Your Personal Data</div>
        <div>The Company may use Personal Data for the following purposes:</div>
        <div className="space">
          To provide and maintain our Service, including to monitor the usage of our Service. To
          manage Your Account: to manage Your registration as a user of the Service. The Personal Data
          You provide can give You access to different functionalities of the Service that are
          available to You as a registered user.
        </div>
        <div>
          For the performance of a contract: the development, compliance and undertaking of the
          purchase contract for the products, items or services You have purchased or of any other
          contract with Us through the Service.
        </div>
        <div>
          To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of
          electronic communication, such as a mobile application&apos;s push notifications regarding
          updates or informative communications related to the functionalities, products or contracted
          services, including the security updates, when necessary or reasonable for their
          implementation. To provide You with news, special offers and general information about other
          goods, services and events which we offer that are similar to those that you have already
          purchased or enquired about unless You have opted not to receive such information. To manage
          Your requests: To attend and manage Your requests to Us. We may share your personal
          information in the following situations:
        </div>
        <div className="space">
          With Service Providers: We may share Your personal information with Service Providers to
          monitor and analyze the use of our Service, to contact You.
        </div>
        <div>
          For Business transfers: We may share or transfer Your personal information in connection
          with, or during negotiations of, any merger, sale of Company assets, financing, or
          acquisition of all or a portion of our business to another company.
        </div>
        <div>
          With Affiliates: We may share Your information with Our affiliates, in which case we will
          require those affiliates to honor this Privacy Policy. Affiliates include Our parent company
          and any other subsidiaries, joint venture partners or other companies that We control or
          that are under common control with Us.
        </div>
        <div>
          With Business partners: We may share Your information with Our business partners to offer
          You certain products, services or promotions
        </div>
        <div>
          With other users: when You share personal information or otherwise interact in the public
          areas with other users, such information may be viewed by all users and may be publicly
          distributed outside. If You interact with other users or register through a Third-Party
          Social Media Service, Your contacts on the Third-Party Social Media Service may see Your
          name, profile, pictures and description of Your activity. Similarly, other users will be
          able to view descriptions of Your activity, communicate with You and view Your profile.
        </div>
        <div>Retention of Your Personal Data</div>
        <div>
          The Company will retain Your Personal Data only for as long as is necessary for the purposes
          set out in this Privacy Policy. We will retain and use Your Personal Data to the extent
          necessary to comply with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and enforce our legal
          agreements and policies.
        </div>
        <div className="space">
          The Company will also retain Usage Data for internal analysis purposes. Usage Data is
          generally retained for a shorter period of time, except when this data is used to strengthen
          the security or to improve the functionality of Our Service, or We are legally obligated to
          retain this data for longer time periods.
        </div>
        <div className="space">Transfer of Your Personal Data</div>
        <div>
          Your information, including Personal Data, is processed at the Company&apos;s operating
          offices and in any other places where the parties involved in the processing are located. It
          means that this information may be transferred to — and maintained on — computers located
          outside of Your state, province, country or other governmental jurisdiction where the data
          protection laws may differ than those from Your jurisdiction.
        </div>

        <div className="space">
          Your consent to this Privacy Policy followed by Your submission of such information
          represents Your agreement to that transfer.
        </div>
        <div className="space">
          The Company will take all steps reasonably necessary to ensure that Your data is treated
          securely and in accordance with this Privacy Policy and no transfer of Your Personal Data
          will take place to an organization or a country unless there are adequate controls in place
          including the security of Your data and other personal information.
        </div>
        <div className="space">Disclosure of Your Personal Data</div>
        <div>Business Transactions</div>
        <div>
          If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be
          transferred. We will provide notice before Your Personal Data is transferred and becomes
          subject to a different Privacy Policy.
        </div>
        <div className="space">Law enforcement </div>
        <div>
          Under certain circumstances, the Company may be required to disclose Your Personal Data if
          required to do so by law or in response to valid requests by public authorities (e.g. a
          court or a government agency).
        </div>
        <div className="space">Other legal requirements </div>
        <div>
          The Company may disclose Your Personal Data in the good faith belief that such action is
          necessary to:
        </div>
        <div className="space">Comply with a legal obligation</div>
        <div>Protect and defend the rights or property of the Company</div>
        <div>Protect the personal safety of Users of the Service or the public</div>
        <div>Protect against legal liability</div>
        <div>Security of Your Personal Data </div>
        <div>
          The security of Your Personal Data is important to Us, but remember that no method of
          transmission over the Internet, or method of electronic storage is 100% secure. While We
          strive to use commercially acceptable means to protect Your Personal Data, We cannot
          guarantee its absolute security.
        </div>
        <div className="space">
          Your California Privacy Rights (California&apos;s Shine the Light law)
        </div>
        <div>
          Under California Civil Code Section 1798 (California&apos;s Shine the Light law), California
          residents with an established business relationship with us can request information once a
          year about sharing their Personal Data with third parties for the third parties&apos; direct
          marketing purposes.
        </div>
        <div className="space">
          If you&apos;d like to request more information under the California Shine the Light law, and
          if you are a California resident, You can contact Us using the contact information provided
          below.
        </div>
        <div className="space">
          California Privacy Rights for Minor Users (California Business and Professions Code Section
          22581)
        </div>
        <div>
          California Business and Professions Code section 22581 allow California residents under the
          age of 18 who are registered users of online sites, services or applications to request and
          obtain removal of content or information they have publicly posted.
        </div>
        <div className="space">
          To request removal of such data, and if you are a California resident, You can contact Us
          using the contact information provided below, and include the email address associated with
          Your account.
        </div>
        <div>
          Be aware that Your request does not guarantee complete or comprehensive removal of content
          or information posted online and that the law may not permit or require removal in certain
          circumstances.
        </div>
        <div className="space">Links to Other Websites</div>
        <div>
          Our Service may contain links to other websites that are not operated by Us. If You click on
          a third party link, You will be directed to that third party&apos;s site. We strongly advise
          You to review the Privacy Policy of every site You visit.
        </div>
        <div className="space">
          We have no control over and assume no responsibility for the content, privacy policies or
          practices of any third party sites or services.
        </div>
        <div className="space">Changes to this Privacy Policy</div>
        <div>
          We may update our Privacy Policy from time to time. We will notify You of any changes by
          posting the new Privacy Policy on this page.
        </div>
        <div className="space">
          We will let You know via email and/or a prominent notice on Our Service, prior to the change
          becoming effective and update the &ldquo;Last updated&rdquo; date at the top of this Privacy
          Policy.
        </div>
        <div className="space">
          You are advised to review this Privacy Policy periodically for any changes. Changes to this
          Privacy Policy are effective when they are posted on this page.
        </div>
        <div className="space">Contact Us</div>
        <div>If you have any questions about this Privacy Policy, You can contact us:</div>
        <div className="space">
          By visiting this page on our website:
          <a href="https://outstrip.biz/" target="_blank" rel="noopener noreferrer" className="link">
            https://outstrip.biz
          </a>
        </div>
      </div>
    </section>
  );
}

export default PrivacyPolicy;
