import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import FacebookSvg from '../../svg/social/facebook.svg';
import InstagramSvg from '../../svg/social/instagram.svg';
import YouTubeSvg from '../../svg/social/youtube.svg';
import LinkedInSvg from '../../svg/social/linkedin.svg';

const Footer = () => {
  const currentLink = { color: '#ffb40d', textDecoration: 'none' };
  const link = { color: 'rgb(166, 167, 170)', textDecoration: 'none' }

  const smLinks = [
    {
      id: 1,
      icon: FacebookSvg,
      title: 'Follow Outstrip on Facebook',
      url: 'https://www.facebook.com/Outstrip01',
    },
    {
      id: 2,
      icon: InstagramSvg,
      title: 'Follow Outstrip on Instagram',
      url: 'https://www.instagram.com/outstripbiz/',
    },
    {
      id: 3,
      icon: YouTubeSvg,
      title: 'Follow Outstrip on YouTube',
      url: 'https://www.youtube.com/@outstripbiz',
    },
    {
      id: 4,
      icon: LinkedInSvg,
      title: 'Follow Outstrip on LinkedIn',
      url: 'https://www.linkedin.com/company/a-new-age-data-intelligence-company',
    }
  ]

  const renderSmLink = (linkData) => {
    return (
      <div key={linkData.id}>
        <a href={linkData.url} target='_blank'>
          <img src={linkData.icon} title={linkData.title} alt={linkData.title} className='w-12 h-12' />
        </a>
      </div>
    );
  }

  const renderSocialMediaIcons = () => {
    return (
      <div>
        <p>Follow us</p>
        <div className='flex items-center'>
          {smLinks.map(renderSmLink)}
        </div>
      </div>
    );
  }

  return (
    <footer className='bg-os-blue text-white p-16'>
      <div className='flex justify-between items-center'>
        <div>
          <div className='md:flex mt-2 text-[#8c90a3]'>
            <NavLink className='block md:inline-flex md:mr-2 mb-3' to='/privacy-policy'>Privacy Policy</NavLink>
            <span className='hidden md:inline'>|</span>
            <NavLink to='/terms-of-service' className='md:pl-2'>Terms of Service</NavLink>
          </div>
        </div>
        {renderSocialMediaIcons()}
      </div>
    </footer>
  );
}

export default Footer;
