import React from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-scroll';

import OutstripLogo from '../component/OutstripLogo';
import TryforFreeButton from '../component/TryforFreeButton';

const Header = () => {
  const renderMobileMenus = () => {
    return (
      <div className='flex'>
        <div>
          <Link
            className='cursor-pointer px-2 md:px-4 text-sm md:text-base'
            to="pricingContainer"
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
          >
            Pricing
          </Link>
        </div>
        <div>
          <a className='text-os-blue px-2 md:px-4 text-sm md:text-base' href="https://app.outstrip.biz/sign-in">Login</a>
        </div>
        <div className='ml-2'>
          <TryforFreeButton type='base' />
        </div>
      </div>
    );
  }

  return (
    <header id='header' className='fixed min-w-full z-10 flex justify-between items-center py-4 px-4 md:px-8 bg-white'>
      <OutstripLogo />
      {renderMobileMenus()}
    </header>
  );
}

export default Header;
