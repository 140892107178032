import React from 'react';
// import Slider from "react-slick";

import facebookImg from '../../../../images/social-media/facebook.png';
import instagramImg from '../../../../images/social-media/instagram.png';
import linkedInImg from '../../../../images/social-media/linkedin.png';
import youTubeImg from '../../../../images/social-media/youtube.png';

const SocialMediaIntegration = () => {
  const settings = {
    infinite: true,
    autoplaySpeed: 2000,
    autoplay: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 3 } },
      { breakpoint: 600, settings: { slidesToShow: 2 } },
      { breakpoint: 480, settings: { slidesToShow: 1 } }
    ]
  };
  return (
    <div className='my-4 grid grid-cols-4 md:grid-cols-8 justify-start items-start'>
      <div className='w-30 mx-4'>
        <img src={facebookImg} alt='Outstrip Facebook Integration' className='m-auto py-3' />
      </div>
      <div className='w-30 mx-4'>
        <img src={instagramImg} alt='Outstrip Instagram Integration' className='m-auto py-3' />
      </div>
      <div className='w-30 mx-4'>
        <img src={linkedInImg} alt='Outstrip LinkedIn Integration' className='m-auto py-3' />
      </div>
      <div className='w-30 mx-4'>
        <img src={youTubeImg} alt='Outstrip YouTube Integration' className='m-auto py-3' />
      </div>
      <div className='w-30 mx-4 hidden md:flex'>
        <img src={facebookImg} alt='Outstrip Facebook Integration' className='m-auto py-3' />
      </div>
      <div className='w-30 mx-4 hidden md:flex'>
        <img src={instagramImg} alt='Outstrip Instagram Integration' className='m-auto py-3' />
      </div>
      <div className='w-30 mx-4 hidden md:flex'>
        <img src={linkedInImg} alt='Outstrip LinkedIn Integration' className='m-auto py-3' />
      </div>
      <div className='w-30 mx-4 hidden md:flex'>
        <img src={youTubeImg} alt='Outstrip YouTube Integration' className='m-auto py-3' />
      </div>
    </div>
  )
};

export default SocialMediaIntegration;
